.guard-details-main {
    width: 100%;
    padding: 0 5%;
    .button-section {
        margin: 0 0 10px 0;
    }
    .guard-details {
        .pull-right {
            min-width: 250px;
            min-height: 250px;
        }
        .form-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            width: 65%;
            gap: 5px;
            .form-group {
                display: flex;
                padding-left: 5px;
                align-items: center;
                width: 100%;
                border: 1px solid rgb(183, 174, 174);
                min-height: 40px;
                border-radius: 3px;
            }
        }
    }
}

.comment-count {
    color: red;
    cursor: pointer;
    margin-right: 5px;
}
.guard-form-row {
    gap: 5px;
}
.guard-form-group {
    width: 100%;
}

.doc-link {
    text-decoration: underline !important;
    text-decoration-color: blue;
}
.doc-link:hover {
    color: black;
}