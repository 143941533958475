@import "styles/main";

.pageContainer {
  min-height: 100vh;
  @include flex(column);
}

.mainContent {
  @include flex(row, flex-start, flex-start);
  margin: 50px 0px 0px 0px;
  flex-grow: 1;
  transition: 0.5s;
}

.expandedMainContent {
  margin-left: 150px;
  transition: 0.5s;
}
